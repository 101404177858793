import Base from '@components/layouts/base';
import CategoryList from '@components/partials/category/list';
import ContentDescription from '@components/partials/common/contentDescription';
import Footer from '@components/partials/common/footer';
import Iframe from '@components/partials/common/iframe';
import Topbar from '@components/partials/common/topbar';
import Header404 from '@components/partials/header/header404';
import { get404Message } from '@data/404Message';
import { getCategoryList } from '@data/category';
import { getIndexData } from '@data/index';
import { getLanguageList } from '@data/languageList';
import { translated404Props } from 'lib/types/item/translated404Props';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { IndexProps } from '../lib/types/page/indexprops';

export default function Index({
    pageData,
    data,
    title,
    translate404Data,
    currentLang
}: {
    pageData: IndexProps;
    data: any;
    title: string;
    translate404Data: translated404Props;
    currentLang: string;
}): JSX.Element {
    const languageList = getLanguageList();
    const [dataApi, setDataApi] = useState(data);
    const [languageData, setLanguageData] = useState(languageList[15]);
    const router = useRouter();
    useEffect(() => {
        languageList.map((lang: any) => {
            if (RegExp('/' + lang.slug).test(router.asPath.substr(0, 3))) {
                setLanguageData(lang);
                (async () => {
                    const res = await getCategoryList(lang.slug);
                    setDataApi(res);
                })();
            }
        });
    }, []);
    const langList = languageList.filter((item: any) => item.slug !== 'en');
    return (
        <Base>
            <Head>
                <title>{title}</title>
                <meta name="robots" content={pageData.metaRobots} />
                <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_DOMAIN} />
                <link
                    rel="alternate"
                    href={process.env.NEXT_PUBLIC_APP_DOMAIN}
                    hrefLang="x-default"
                />
                {langList.map((lang: any) => {
                    return (
                        <link
                            key={lang.slug}
                            rel="alternate"
                            href={process.env.NEXT_PUBLIC_APP_DOMAIN + lang.slug + '/'}
                            hrefLang={lang.hreflang}
                        />
                    );
                })}
            </Head>
            <div className="wrapper">
                <Topbar
                    categoryList={dataApi.categoryList}
                    homeSlug={'/' + currentLang}
                    is404Page={true}
                    isHomePage={true}
                />
                <Header404 translated404Data={translate404Data} />
                <CategoryList categoryList={dataApi.categoryList} language={languageData.slug} />
                <Iframe language={languageData.slug} />
                <ContentDescription
                    content={dataApi.content}
                    contentEmail={dataApi.emailText}
                    currentLang={currentLang}
                />
                <Footer />
            </div>
        </Base>
    );
}

// Get the data required to populate the page
export const getStaticProps: GetStaticProps = async (context: any) => {
    const currentLang = context.locale;
    const data = await getCategoryList(currentLang);
    const translate404Data = await get404Message(currentLang);
    const title = data.title;
    const pageData = await getIndexData();

    if (!pageData) {
        return {
            notFound: true
        };
    }
    return {
        props: {
            pageData,
            data,
            title,
            translate404Data,
            currentLang
        },
        revalidate: 300
    };
};
