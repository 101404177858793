import styles from '@styles/scss/common/_iframe.module.scss';
import { windowWidth } from 'lib/helper/windowWidth';
import { useEffect, useState } from 'react';

export default function Iframe({ language }: { language: string }): JSX.Element {
    const [reload, setReload] = useState(true);
    const [iframe, setIframe] = useState({
        width: '300',
        height: '250'
    });
    const width = windowWidth();
    useEffect(() => {
        if (width > 1165) {
            setIframe({ width: '1144', height: '250' });
            setReload(false);
        } else if (width > 995) {
            setIframe({ width: '962', height: '102' });
            setReload(false);
        } else if (width > 760) {
            setIframe({ width: '728', height: '90' });
            setReload(false);
        } else if (width > 635) {
            setIframe({ width: '600', height: '250' });
            setReload(false);
        } else {
            setIframe({ width: '300', height: '250' });
            setReload(false);
        }
    }, [width]);

    const [tracking, setTracking] = useState('520');
    const [lang, setLang] = useState(language || '');

    const assignValues = (lang: string) => {
        if (lang === 'fr') {
            setTracking('871');
        } else if (lang === 'de') {
            setTracking('895');
        } else if (lang === 'es') {
            setTracking('844');
        } else if (lang === 'it') {
            setTracking('865');
        } else if (lang === 'nl') {
            setTracking('872');
        } else if (lang === 'pt') {
            setTracking('883');
        } else if (lang === 'sv') {
            setTracking('873');
        } else {
            setLang('');
        }
    };

    useEffect(() => {
        if (!reload) {
            assignValues(lang);
            setReload(true);
        } else {
            assignValues(lang);
        }
    }, [reload]);

    return (
        <div className={styles.container}>
            {reload && (
                <iframe
                    src={
                        'https://www.pennynetwork.com/friends/' +
                        lang +
                        'myfavsexcamsxxx/' +
                        tracking +
                        '-' +
                        lang +
                        'myfavsexcamsxxx.html?frameWidth=' +
                        iframe.width +
                        '&ail=1&ref=https://www.myfavsexcams.xxx/' +
                        lang
                    }
                    width={iframe.width}
                    height={iframe.height}
                    data-loc={
                        'www.pennynetwork.com/friends/' +
                        lang +
                        'myfavsexcamsxxx/' +
                        tracking +
                        '-' +
                        lang +
                        'myfavsexcamsxxx.html'
                    }
                    data-res="true"
                    data-ref="false"
                    id={'rrAsync' + tracking}
                    className="aoembed"
                    scrolling="no"
                    title="Rabbits Webcam"></iframe>
            )}
        </div>
    );
}
