import { getSiteReview } from '@data/siteReview';
import { SiteSmallType } from 'lib/types/item/siteSmalltype';

import { fetchApi } from './fetchApi';

// to do api call to get all the category with related  sites from cms
export async function getCategoryList(lang?: string): Promise<any> {
    const domain = process.env.NEXT_PUBLIC_API_DOMAIN;
    const dataApi = await fetchApi(domain + 'v3/home', lang);

    const data = {};
    const categoryList = {};
    const pagination: SiteSmallType[] = [];
    if (dataApi) {
        const topSiteList: any[] = [];
        const topSitesCategories = dataApi.Home.topSitesCategories;
        if (topSitesCategories !== undefined) {
            for (const topSite of topSitesCategories) {
                if (topSite.siteSlug !== null && topSite.categorySlug !== null) {
                    const siteReviewData = await getSiteReview(topSite.siteSlug, lang);

                    const siteData = {
                        categoryName: topSite.categoryName,
                        categorySlug: topSite.categorySlug,
                        name: topSite.siteName,
                        slug: topSite.siteSlug,
                        tourlink: siteReviewData.tourlink,
                        useOpensite: topSite.useOpensite,
                        image: siteReviewData.image,
                        scoreContent: siteReviewData.scoreContent,
                        scoreFeature: siteReviewData.scoreFeature,
                        scoreValue: siteReviewData.scoreValue,
                        scoreMfsc: siteReviewData.scoreMfsc,
                        contentTitle: siteReviewData.contentTitle,
                        featuresTitle: siteReviewData.featuresTitle,
                        valueTitle: siteReviewData.valueTitle,
                        mfscTitle: siteReviewData.mfscTitle,
                        pro1: siteReviewData.pro1,
                        pro2: siteReviewData.pro2,
                        pro3: siteReviewData.pro3,
                        isReviewActive: siteReviewData.isReviewActive
                    };

                    topSiteList.push(siteData);
                }
            }
        }

        dataApi.Home.sortedResults.map((obj: any) => {
            const list: any = [];
            const activeList: any = [];
            let position = 1;

            obj.categorySites.map((item: any) => {
                let externalURL = item.tourlink || item.link;
                if (externalURL.includes('ids=')) {
                    externalURL = externalURL.replace('ids=', 'id=');
                }
                const renamedObj = {
                    sitePosition: position++,
                    siteName: item.name,
                    siteIconLogo: item.icon,
                    siteTrending: item.isTrending,
                    siteNew: item.isNew,
                    siteDiscount: item.isDiscount,
                    siteUseOpenSite: item.useOpensite,
                    siteOutgoingLink: externalURL,
                    siteReviewSlug: item.slug,
                    siteReviewActive: item.isReviewActive,
                    siteReviewPublished: item.isReviewPublished,
                    siteCardIntro: item.intro || '',
                    siteActive: item.isListActive,
                    siteCategorySlug: item.categorySlug,
                    siteHasFlag: item.hasFlag
                };
                list.push(renamedObj);
                if (item.isListActive) {
                    activeList.push(renamedObj);
                }
            });

            const categoryObj = {
                categoryName: obj.categoryName || obj.categorySlug,
                categoryMiniDescription: obj.categoryMiniDescription || '',
                categoryIcon: '',
                categorySites: list,
                activeSites: activeList || [],
                categorySlug: obj.categorySlug,
                categoryActive: obj.isListActive,
                categoryReviewActive: obj.isReviewActive,
                categoryReviewPublished: obj.isReviewPublished,
                categoryRanking: obj.ranking,
                categoryColumn: obj.col
            };

            const paginationObj = {
                name: obj.categoryName || obj.categorySlug,
                slug: obj.categorySlug
            };
            if (obj.isListActive && obj.isReviewActive) {
                pagination.push(paginationObj);
            }

            Object.assign(categoryList, { [obj.categorySlug]: categoryObj });
        });

        Object.assign(data, {
            intro: dataApi.Home.intro.intro,
            emailText: dataApi.Home.intro.emailText,
            title: dataApi.Home.intro.pageTitle,
            metaDescription: dataApi.Home.intro.metaDescription
        });
        Object.assign(data, { content: dataApi.Home.intro.content });
        Object.assign(data, { categoryList: categoryList });
        Object.assign(data, { categoryPagination: pagination });
        Object.assign(data, { topSiteDataList: topSiteList });
    }

    return data;
}
