import styles from '@styles/scss/common/_header404.module.scss';
import { translated404Props } from 'lib/types/item/translated404Props';

export default function Header404({
    translated404Data
}: {
    translated404Data: translated404Props;
}): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.bubble}>
                <div dangerouslySetInnerHTML={{ __html: translated404Data.errorMessage }} />
            </div>
        </div>
    );
}
