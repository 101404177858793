import * as Sentry from '@sentry/nextjs';

export async function fetchApi(url: string, lang?: string): Promise<any> {
    const endpoint = url + '?lang=' + (lang === undefined ? 'en' : lang);
    const response = await fetch(endpoint);
    let dataApi;
    let responseText;

    try {
        responseText = await response.text();
        dataApi = JSON.parse(responseText);
    } catch (jsonError) {
        Sentry.captureException(jsonError, {
            extra: { responseText }
        });
        throw new Error('Failed to parse JSON response');
    }

    if (dataApi.error) {
        console.log('--------------------------------------------------');
        console.log('- Error thrown from API endpoint:');
        console.log(endpoint);
        console.log('- Error:');
        console.log(dataApi.error);
        console.log('--------------------------------------------------');
    } else {
        return dataApi;
    }
}
