import { fetchApi } from './fetchApi';

// to do api call to get all the category with related  sites from cms
export async function getSiteReview(site: string, lang?: string): Promise<any> {
    const domain = process.env.NEXT_PUBLIC_API_DOMAIN;
    const data = await fetchApi(domain + 'v3/site-review/' + site, lang);
    if (data !== undefined || (data && data.isReviewPublished)) {
        data.tourlink = data.tourlink.replace('ids=', 'id=');
        return data;
    } else {
        return false;
    }
}
